import { useTranslate } from 'src/locales';
import { useMemo, useState, useEffect } from 'react';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { paths } from 'src/routes/paths';

import SvgColor from 'src/components/svg-color';

import Iconify from '../../components/iconify';
import GeneralService from '../../services/GeneralService';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  timesheet: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard')
};

// ----------------------------------------------------------------------

export function useNavData(user) {
  const { t } = useTranslate();

  const [links, setLinks] = useState([]);

  const fetchLinks = async () => {
    if (user?.data?.companies && user?.data?.current_company && Object.keys(user?.data?.companies).length > 0 && user?.data?.companies[user?.data?.current_company].payroll_id) {
      const res = await GeneralService.getExternalLinks(user.data.companies[user.data.current_company].payroll_id);
      setLinks(res.data);
    }
  }

  useEffect(() => {
    if (user && user?.data?.company && user?.data?.groups.includes('Azienda')) {
      fetchLinks().then()
    }
  }, [user]);

  const theme = useTheme();

  const smUp = useMediaQuery(theme.breakpoints.up('sm'));

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        items: [
          {
            title: t('dashboard'),
            path: paths.dashboard.root,
            icon: ICONS.dashboard
          },
          ...((user && (user?.groups?.includes('Azienda') || user?.groups?.includes('Responsabile'))) ? [{
            title: t('employees'),
            path: paths.dashboard.employees,
            icon: ICONS.user
          }] : []),
          ...((user && (user?.groups?.includes('Azienda') || user?.groups?.includes('Responsabile') || user?.groups?.includes('Timesheet')) && smUp) ? [{
            title: t('timesheet'),
            path: paths.dashboard.timesheet,
            icon: ICONS.timesheet
          }] : []),
          ...((user
            && (user?.groups?.includes('Azienda') || user?.groups?.includes('Responsabile'))
            && user?.data?.current_company && user?.data?.companies[user?.data?.current_company]?.feature_requests
          ) ? [{
            title: t('requests'),
            path: paths.dashboard.requests,
            icon: <Iconify sx={{ width: 24, height: 24 }} icon="solar:calendar-search-bold-duotone"/>,
          }] : []),
          ...((user && user?.groups?.includes('Azienda') && smUp) ? [{
            title: t('notices'),
            path: paths.dashboard.notices,
            icon: ICONS.mail
          }] : []),
          ...((user && (user?.groups?.includes('Azienda') || user?.groups?.includes('Responsabile'))
            && user?.data?.current_company && user?.data?.companies[user?.data?.current_company]?.feature_expense_reports) ? [{
            title: t('expense_reports'),
            path: paths.dashboard.expenseReports,
            icon: ICONS.invoice
          }] : []),
          ...(user && user?.groups?.includes('Azienda') ? [{
            title: t('documents'),
            path: paths.dashboard.documents,
            icon: ICONS.file
          },
            {
              title: t('letters'),
              path: paths.dashboard.letters,
              icon: <Iconify sx={{ width: 24, height: 24 }} icon="solar:chat-line-bold-duotone"/>,
            }] : []),
          ...((user && (user?.groups?.includes('Azienda') || user?.groups?.includes('Responsabile'))) ? [{
            title: t('timetable'),
            path: paths.dashboard.calendar,
            icon: ICONS.calendar
          }] : []),
          ...((user && user?.groups?.includes('Azienda') && smUp) ? [{
            title: t('help_desk'),
            path: paths.dashboard.helpDesk,
            icon: ICONS.chat
          },
            {
              title: t('stats'),
              path: paths.dashboard.stats,
              icon: ICONS.analytics,
            },
            {
              title: t('news'),
              path: paths.dashboard.news.root,
              icon: ICONS.label
            },
            {
              title: t('faq'),
              path: paths.dashboard.faq,
              icon: <Iconify sx={{ width: 24, height: 24 }} icon="solar:diploma-verified-bold-duotone"/>
            },
            {
              title: t('dati contabili'),
              path: paths.dashboard.payrollData,
              icon: <Iconify sx={{ width: 24, height: 24 }} icon="solar:chart-bold-duotone"/>,
            }] : []),

          ...((user && (user?.groups?.includes('Azienda') || user?.groups?.includes('Responsabile'))) ? [
          {
            title: t('report'),
            path: '#',
            icon: ICONS.analytics,
            children: [
              ...((user && user?.data?.current_company && user?.data?.companies
                && user?.data?.companies[user?.data?.current_company]?.timesheet_types?.some((type) => ['clocking', 'precompiled_company_clocking', 'precompiled_employee_clocking'].includes(type))) ? [{
                title: "Timbrature",
                path: paths.dashboard.clockings,
                icon: <Iconify sx={{ width: 24, height: 24 }} icon="solar:clock-circle-bold-duotone"/>,
              }] : []),

              ...((user && user?.groups?.includes('Azienda') && smUp) ? [{
                title: "Utenti",
                path: paths.dashboard.employeesSecurityReport,
                icon: <Iconify sx={{ width: 24, height: 24 }} icon="solar:folder-security-bold-duotone"/>,
              }] : []),
            ]
          }] : []),
          ...(smUp && user && user?.groups?.includes('Azienda') && links.length > 0 ? [{
            title: t('external_services'),
            path: '#/external',
            icon: ICONS.external,
            children: links.map((link) => (
              {
                title: link.description,
                path: link.url.startsWith('http') ? link.url : `https://${link.url}`,
                externalLink: true,
              }
            )),
          }] : []),
          ...((user && user?.groups?.includes('Azienda')) ? [{
            title: t('config'),
            path: paths.dashboard.config.root,
            icon: <Iconify sx={{ width: 24, height: 24 }} icon="solar:settings-bold-duotone"/>,
            children: [
              ...(smUp ? [{
                title: t('general'),
                path: paths.dashboard.config.root,
              }] : []),
              ...(smUp ? [{
                title: t('timesheet'),
                path: paths.dashboard.config.timesheet,
              }] : []),
              ...((user && user?.groups?.includes('Azienda') && smUp
                && user?.data?.current_company && user?.data?.companies[user?.data?.current_company]?.clocking_mode === 'qr') ? [{
                title: t('clocking places'),
                path: paths.dashboard.config.clockingPlaces,
              }] : []),
              ...((user && user?.groups?.includes('Azienda')
                && user?.data?.current_company && user?.data?.companies[user?.data?.current_company]?.feature_expense_reports) ? [{
                title: t('expense_reports'),
                path: paths.dashboard.config.expenseReports,
              }] : []),
              {
                title: t('supervisors'),
                path: paths.dashboard.config.supervisors,
              },
            ],
          }] : []),
          ...(user && user?.groups?.includes('Azienda') ? [{
            title: t('your company'),
            path: paths.dashboard.company,
            icon: ICONS.job
          }] : []),
        ]
      }
    ],
    [t, links, user]
  );

  return data;
}
